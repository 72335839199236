export const globalMixin = {
    data() {
        return {
            examOptions: [{
                    value: null,
                    text: 'Semua'
                }, {
                    value: 'sop&sk',
                    text: 'SOP&SK',
                    exam_type : 'Default'
                },
                {
                    value: 'value',
                    text: 'Value',
                    exam_type : 'ICES'
                },
                {
                    value: 'others',
                    text: 'Others',
                    exam_type : 'Default'
                }
            ]
        }
    },
    methods: {
        examType(type) {
            if (type.toLowerCase() == "default") return 'SOP&SK'
            return 'Value'
        },
        getCustomFirstExamOptions(placeholder) {
            this.examOptions[0].text = placeholder;
            return this.examOptions;
        }
    },
};